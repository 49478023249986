import React, { useEffect, useState } from 'react';
import Numbers from '../landing/Numbers';
import ModalB2B from '../shared/ModalB2B';
import Beneficios from './Beneficios/Beneficios';
import CondutaInterativa from './CondutaInterativa/CondutaInterativa';
import Demonstracao from './Demonstracao/Demonstracao';
import IntegracaoSistema from './IntegracaoSistema/IntegracaoSistema';
import PrincipaisVantagens from './principaisVantagens/PrincipaisVantagens';
import Video from './VideoDemonstrativo/Video';

function ContentB2B() {
  const [showModal, setShowModal] = useState(false);
  const [initialData, setInitialData] = useState({ email: '' });
  const [firstRender, setFirstRender] = useState(true);

  /* Handle show modal action */
  const showModalEvent = (event, dataToModal) => {
    event.preventDefault();
    setShowModal(true);
    setInitialData(dataToModal);
  };

  /* Handle close modal action */
  const onCloseModal = (event) => {
    event.preventDefault();
    setShowModal(false);
  };

  useEffect(() => {
    if (firstRender) {
      window.scrollTo(0, 0);
      setFirstRender(false);
    }
  });

  return (
    <>
      <PrincipaisVantagens />
      <CondutaInterativa />
      <Video openModal={showModalEvent} />
      <Demonstracao />
      <Beneficios openModal={showModalEvent} />
      <Numbers />
      <IntegracaoSistema />
      <ModalB2B
        show={showModal}
        onClose={onCloseModal}
        initialData={initialData}
      />
    </>
  );
}

export default ContentB2B;
