import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import ReactPlayer from 'react-player';
import fegow from '../../../assets/img/logos/feegow.svg';
import goclin from '../../../assets/img/logos/goclin.svg';
import mkdata from '../../../assets/img/logos/mkdata.png';
import mv from '../../../assets/img/logos/mv.svg';
import prontmed from '../../../assets/img/logos/prontmed.svg';
import tasy from '../../../assets/img/logos/tasy.png';
import videoDemonstracao from '../../../assets/video/medflow-demonstracao.mp4';
import './Video.scss';

function Video({ openModal }) {
  const videoRef = useRef(null);
  const logos = [
    { src: fegow, alt: 'Fegow' },
    { src: goclin, alt: 'Goclin' },
    { src: mkdata, alt: 'MK Data' },
    { src: mv, alt: 'MV' },
    { src: prontmed, alt: 'Prontmed' },
    { src: tasy, alt: 'Tasy' },
  ];

  return (
    <section className="section-video">
      <div className="title-board">
        <h2 className="title-board__title">
          Veja como funciona a integração do Medflow.
        </h2>
      </div>
      <div className="medflow-demonstracao__video">
        <ReactPlayer
          ref={videoRef}
          className="react-player"
          width="100%"
          height="100%"
          url={videoDemonstracao}
          controls
          volume={0.8}
        />
      </div>
      <div className="medflow-demonstracao__integracoes">
        <div className="medflow-demonstracao__integracoes__content">
          <div className="medflow-demonstracao__integracoes__title">
            Já estamos integrados com diversos prontuários.
          </div>
          <div className="medflow-demonstracao__integracoes__list">
            {logos.map((logo) => (
              <div
                key={logo.src}
                className="medflow-demonstracao__integracoes__list__item"
              >
                <img src={logo.src} alt={logo.alt} />
              </div>
            ))}
          </div>
        </div>
        <span
          role="button"
          tabIndex={0}
          style={{ marginLeft: 0 }}
          className="banner__button banner__button__primary medflow-demonstracao__integracoes__button"
          onClick={openModal}
        >
          Saiba mais
        </span>
      </div>
    </section>
  );
}
Video.propTypes = {
  openModal: PropTypes.func.isRequired,
};

export default Video;
